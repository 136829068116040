<template lang="pug">
  Auth(@loggedIn="onLoggedIn"
    :redirectWithLoginFailed="true")
    Header(:content="headerContent")
    div.wrap-set-master-slots-condition
      ModuleSetMasterSlotsCondition(ref="moduleComponent")

</template>

<style lang="scss" scoped>
.wrap-set-master-slots-condition {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import ModuleSetMasterSlotsCondition from '@/components/module/ModuleSetMasterSlotsCondition'

export default {
  components: {
    Auth,
    Header,
    ModuleSetMasterSlotsCondition
  },
  data() {
    return {
      headerContent: {
        title: '空きスロット設定',
        left: {
          icon: 'mdi-chevron-left',
          action: this.back
        },
        right: {
          label: '保存',
          color: '#4285f4',
          action: this.save
        }
      },
      showMenu: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    onLoggedIn() {},
    async save() {
      const moduleComponent = this.$refs.moduleComponent
      const condition = {
        duration: moduleComponent.duration,
        timeRange: moduleComponent.timeRange,
        dayOfWeek: moduleComponent.dayOfWeek.filter((d) => d.active).map((d) => d.day)
      }
      console.log('condition:', condition)
      await database.settingsCollection().update(this.uid, { masterSlotsCondition: condition })
      alert('保存しました')
      this.$router.go(-1)
    },
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
